/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "MAINTENANCE_MODE": false,
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "mail",
            "endpoint": `https://api.lexconnect.cloud/mail`,
            "region": "us-east-1"
        },
        {
            "name": "google",
            "endpoint": `https://api.lexconnect.cloud/google`,
            "region": "us-east-1"
        },
        {
            "name": "switch",
            "endpoint": `https://api.lexconnect.cloud/switch`,
            "region": "us-east-1"
        },
        {
            "name": "switchPivots",
            "endpoint": `https://pivot.lexconnect.cloud/switchPivots`,
            "region": "us-east-1"
        },
        {
            "name": "bandwidth",
            "endpoint": `https://api.lexconnect.cloud/bandwidth`,
            "region": "us-east-1"
        },
        {
            "name": "apiMappings",
            "endpoint": `https://api.lexconnect.cloud/api`,
            "region": "us-east-1"
        },
        {
            "name": "frontend",
            "endpoint": `https://api.lexconnect.cloud/frontend`,
            "region": "us-east-1"
        },
        {
            "name": "calix",
            "endpoint": `https://api.lexconnect.cloud/calix`,
            "region": "us-east-1"
        },
        {
            "name": "psql",
            "endpoint": "https://api.lexconnect.cloud/psql",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://rv22bqbiy5hdtfffz6ufeapari.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-arfv2rq2u5cujld36qpoywobc4",
    "aws_cognito_identity_pool_id": "us-east-1:8588bc04-bf47-44aa-a474-a7c2936aaeb8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HtFT0kO5k",
    "aws_user_pools_web_client_id": "6k1rbvkbelrmeut1bluls6b0u3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lex-connect-site-uploads154944-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
