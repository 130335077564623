<template>
    
    <v-layout>
        <v-main id="layoutContainer" class="d-flex align-center justify-center">
            
            <div id="messageContainer">
                <p>We are currently performing scheduled maintenance to improve our site. We appreciate your patience and will be back online shortly.</p>
                <img id="forkLiftImage" src="@/assets/forklift.png" alt="">
            </div>



        </v-main>
    </v-layout>    



</template>


<script setup>
</script>


<style scoped>
    #layoutContainer {
        height: 100vh;
        background: url("@/assets/loginpage_image.png") top center no-repeat;
        background-size: 100%, contain;
        text-align: center;
    }

    #messageContainer {
        background-color: white;
        width: 30%;
        padding: 25px;
        border-radius: 5px;
    }

    #forkLiftImage {
        margin-top: 10px;
        width: 50px;
    }

</style>